<template>
    <b-overlay :show="loading" rounded="sm">
        <b-row>
            <b-col cols="12">
                <!-- 1 -->
                <b-card no-body class="mb-4">
                    <!-- Search Control -->
                    <b-card-body class="p-3 d-flex justify-content-between align-items-center">
                        <b-form-group class="mb-2 w-25">
                            <b-input-group>
                                <b-form-input
                                    id="filter-input"
                                    v-model="filter"
                                    type="search"
                                    placeholder="Type to Search"
                                    @input="debouncedSearch"
                                ></b-form-input>

                                <b-input-group-append>
                                    <b-button variant="danger" :disabled="!filter" @click="filter = ''">Clear</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>

                        <div class="d-flex align-items-end">
                            <b-form-select
                                v-model="statusSelected"
                                @change="getData"
                                :options="statusOptions"
                                class="mr-2"
                                placeholder="Select Status"
                            />
                            <b-button
                                style="text-wrap: nowrap"
                                variant="primary"
                                @click="$router.push('/applications/new')"
                                >Create Application</b-button
                            >
                        </div>
                    </b-card-body>
                    <!-- End Section -->

                    <!-- Data Table -->
                    <b-table
                        style="min-height: 250px"
                        show-empty
                        responsive
                        small
                        class="mb-0"
                        head-variant="light"
                        :items="applications"
                        :fields="fields"
                        hover
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        no-local-sorting
                    >
                        <template #cell(id)="data"> #{{ generateCode(data.item.id) }} </template>
                        <template #cell(isActive)="data">
                            <b-badge variant="success" v-if="data.item.isActive">Active</b-badge>
                            <b-badge variant="danger" v-else>Inactive</b-badge>
                        </template>
                        <template #cell(createAt)="data">
                            {{ $formatDate(data.item.createAt) }}
                        </template>
                        <template #cell(progress)="data">
                            <b-progress
                                :value="data.item.progressBarValue"
                                height="12px"
                                style="width: 120px"
                                class="bg-light-secondary"
                            ></b-progress>
                        </template>

                        <template #cell(currentStatus)="data">
                            {{ `${data.item.processStatusCode} - ${data.item.processStatusName}` }}
                        </template>

                        <template #cell(loanAmount)="data"> {{ $formatCurrency(data.item.loanAmount) }} </template>

                        <template #cell(monthlyPayment)="data">
                            {{ $formatCurrency(data.item.monthlyPayment) }}
                        </template>

                        <template #cell(action)="data">
                            <b-dropdown variant="primary" id="dropdown-1" text="Actions" class="">
                                <b-dropdown-item @click="goToEdit(data.item)">See process</b-dropdown-item>
                                <b-dropdown-item @click="showOfficerModal(data.item)"
                                    >Change Loan Officer</b-dropdown-item
                                >

                                <LoanApplicationReport :data="data.item" />
                            </b-dropdown>
                        </template>
                    </b-table>
                    <!-- End Section -->

                    <!-- Pagination -->
                    <div class="p-3 d-flex align-items-center">
                        <b-form-group
                            label="Per page"
                            label-for="per-page-select"
                            label-cols-sm="6"
                            label-cols-md="4"
                            label-cols-lg="9"
                            label-size="sm"
                            label-class="fw-medium"
                            class="mb-0"
                        >
                            <b-form-select
                                @change="getData()"
                                id="per-page-select"
                                v-model="perPage"
                                :options="pageOptions"
                                size="sm"
                            ></b-form-select>
                        </b-form-group>
                        <div class="ml-auto">
                            <b-pagination
                                @input="getData()"
                                v-model="currentPage"
                                :total-rows="totalApplications"
                                :per-page="perPage"
                                align="fill"
                                class="my-0"
                            ></b-pagination>
                        </div>
                    </div>
                    <!-- End Section -->
                </b-card>
            </b-col>
        </b-row>

        <!-- Modal -->
        <b-modal id="modal-view" centered title="Details" v-model="show" @hide="show = false" hide-footer>
            <b-card-text v-if="applicationSelected">
                <Stepper />
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Customer Name</h6>
                    <span class="mail-desc d-block text-muted">{{ applicationSelected.customerName }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Loan Amount</h6>
                    <span class="mail-desc d-block text-muted">{{ applicationSelected.loanAmount }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Monthly Payment</h6>
                    <span class="mail-desc d-block text-muted">{{ applicationSelected.monthlyPayment }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Period In Months</h6>
                    <span class="mail-desc d-block text-muted">{{ applicationSelected.periodInMonths }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Loan Type</h6>
                    <span class="mail-desc d-block text-muted">{{ applicationSelected.loanDescription }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Agent Name</h6>
                    <span class="mail-desc d-block text-muted">{{ applicationSelected.agentName }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Created By</h6>
                    <span class="mail-desc d-block text-muted">{{ applicationSelected.createdByUserName }}</span>
                </div>
                <div class="border-bottom d-flex justify-content-between py-2">
                    <h6 class="message-title text-primary mb-0 mt-1">Status</h6>
                    <span class="mail-desc d-block text-muted">{{
                        applicationSelected.isActive ? 'Active' : 'Inactive'
                    }}</span>
                </div>
            </b-card-text>
        </b-modal>

        <b-modal id="modal-officer" centered title="Change Loan Officer" hide-footer>
            <div>
                <label for="">Select an officer</label>
                <b-form-select
                    class="mb-3"
                    :options="officers"
                    v-model="selectedOfficer"
                    value-field="id"
                    text-field="userName"
                    size="sm"
                ></b-form-select>

                <b-button variant="primary" @click="setOfficer" :disabled="selectedOfficer == null">Save</b-button>
            </div>
        </b-modal>
    </b-overlay>
</template>

<script>
import { mapGetters } from 'vuex';
import Stepper from '../../components/Stepper.vue';
import { debounce } from 'lodash';
import LoanApplicationReport from '../../components/reports/LoanApplicationReport.vue';
export default {
    name: 'applications',
    components: {
        Stepper,
        LoanApplicationReport,
    },

    data: () => ({
        page: {
            title: 'applications',
        },
        sortBy: '',
        sortDesc: true,
        sortColumns: {
            applicationCode: 'applicationCode',
            agentName: 'agentName',
            nationalID: 'nationalID',
            customerName: 'customerName',
            loanDescription: 'loanDescription',
            loanAmount: 'loanAmount',
            periodInMonths: 'periodInMonths',
            isActive: 'isActive',
            createAt: 'createAt',
        },

        fields: [
            {
                key: 'applicationCode',
                label: 'Application Code',
                sortable: true,
            },
            {
                key: 'agentName',
                label: 'Loan Officer',
                sortable: true,
            },
            {
                key: 'nationalID',
                label: 'Applicant ID',
                sortable: true,
            },
            {
                key: 'customerName',
                label: 'Applicant',
                sortable: true,
            },
            {
                key: 'coApplicantNationalID',
                label: 'CoApplicant ID',
                sortable: false,
            },
            {
                key: 'coApplicantCustomerName',
                label: 'CoApplicant',
                sortable: false,
            },
            {
                key: 'loanAmount',
                label: 'Loan Amount',
                sortable: true,
            },
            {
                key: 'periodInMonths',
                label: 'Months',
                sortable: true,
            },
            {
                key: 'loanDescription',
                label: 'Loan Type',
                sortable: true,
            },
            {
                key: 'currentStatus',
                label: 'Status',
                sortable: true,
            },
            {
                key: 'createAt',
                label: 'Create At',
                sortable: true,
            },
            {
                key: 'progress',
                label: 'Progress',
                sortable: false,
            },
            {
                key: 'action',
                label: 'Action',
                sortable: false,
            },
        ],
        loading: false,
        filter: '',
        totalRows: 1,
        currentPage: 1,
        perPage: 10,
        pageOptions: [5, 10, 20, 40, { value: 100, text: 'Show a lot' }],
        applicationSelected: null,
        show: false,
        officers: [],
        selectedOfficer: null,
        selected: null,

        routes: [
            {
                path: '/applications',
            },
            {
                path: '/applications/assigned',
                slug: 'assigned',
            },
            {
                path: '/applications/additional-details',
                slug: 'fill-additional-details',
            },
            {
                path: '/applications/membercheck',
                slug: 'membercheck',
            },
            {
                path: '/applications/screening',
                slug: 'screening',
            },
            {
                path: '/applications/job-verification',
                slug: 'job-verification',
            },
            {
                path: '/applications/verify-all',
                slug: 'verify-all-information',
            },
            {
                path: '/applications/approval',
                slug: 'approval-lvl-1.2',
            },
            {
                path: '/applications/signature',
                slug: 'signature',
            },
            {
                path: '/applications/transfer-funds',
                slug: 'transfer-of-funds',
            },
            {
                path: '/applications/closed',
                slug: 'closed',
            },
            {
                path: '/applications/completed',
                slug: 'completed',
            },
        ],

        statusOptions: [
            { value: null, text: 'All' },
            { value: '010', text: 'Loan Request created' },
            { value: '030', text: 'Details' },
            { value: '031', text: 'Details: Missing Information' },
            { value: '032', text: 'Details: Updated' },
            { value: '040', text: 'Compliance' },
            { value: '041', text: 'Compliance: Missing Information' },
            { value: '042', text: 'Compliance: Updated' },
            { value: '050', text: 'Screening' },
            { value: '051', text: 'Screening: Submitted' },
            { value: '052', text: 'Screening: Passed' },
            { value: '053', text: 'Screening: Failed' },
            { value: '060', text: 'Job Verification' },
            { value: '061', text: 'Job Verification: Submitted' },
            { value: '062', text: 'Job Verification: Passed' },
            { value: '063', text: 'Job Verification: Failed' },
            { value: '070', text: 'Submited to 1st line of approval' },
            { value: '071', text: 'Approved by 1st line of approval' },
            { value: '080', text: 'Submited to 2nd line of approval' },
            { value: '081', text: 'Approved by 2nd line of approval' },
            { value: '090', text: 'Submited to 3rd line of approval' },
            { value: '100', text: 'Returned for revision' },
            { value: '110', text: 'Further Review Required' },
            { value: '120', text: 'Application Approved pending results' },
            { value: '130', text: 'Application Approved' },
            { value: '140', text: 'Application Approved with exception' },
            { value: '150', text: 'Cancel Application' },
            { value: '151', text: 'Application Cancelled' },
            { value: '160', text: 'Deny Application' },
            { value: '161', text: 'Application Denied' },
            { value: '170', text: 'Verify for Signing' },
            { value: '171', text: 'Return for corrections 1' },
            { value: '175', text: 'Ready for Signing' },
            { value: '180', text: 'Documents Signed' },
            { value: '190', text: 'Verify for Disbursement' },
            { value: '191', text: 'Return for corrections 2' },
            { value: '195', text: 'Ready for Disbursement' },
            { value: '200', text: 'Loan Created' },
            { value: '210', text: 'Disburse Funds' },
            { value: '220', text: 'Application Completed' },
        ],
        statusSelected: null,
    }),
    methods: {
        async getData() {
            const sortDirection = this.sortDesc ? 'desc' : 'asc';
            const sortProperty = this.sortColumns[this.sortBy] ?? '';

            this.loading = true;
            await this.$store.dispatch('loan/applications/get_allApplications', {
                skip: this.currentPage,
                take: this.perPage,
                slug: this.$route.query.slug,
                processStatusCode: this.statusSelected,
                query: this.filter,
                sortDirection,
                sortProperty,
            });

            this.loading = false;
        },
        async goToView(item) {
            await this.$store.dispatch('customer/productRequest/get_processResquest', item.id);
            this.applicationSelected = item;
            this.show = true;
        },
        goToEdit(item) {
            this.$router.push(`/applications/list/${item.customerId}?loanApplicationId=${item.id}`);
        },
        checkProgress(data) {
            let count = 0;
            const items = [
                'main',
                'additionalDetails',
                'membercheck',
                'screening',
                'jobVerification',
                'approval',
                'signed',
                'transferredFunds',
            ];
            items.forEach((element) => {
                if (element == 'approval') {
                    if (data.approvalLvl1 && data.approvalLvl1_2 && data.approvalLvl2) count += 12.5;
                }
                if (data[element]) count += 12.5;
            });
            return count;
        },
        generateCode(id) {
            let idStr = String(id);
            let zerosNeeded = Math.max(0, 7 - idStr.length);
            let code = 'LA-' + '0'.repeat(zerosNeeded) + idStr;

            return code;
        },
        generateReport() {},
        async showOfficerModal(item) {
            this.selected = item;
            const response = await this.$store.dispatch('user/get_loanOfficers');
            this.officers = response.dataResult;
            this.$bvModal.show('modal-officer');
        },
        async setOfficer() {
            let data = {
                loanId: this.selected.id,
                agentId: this.selectedOfficer,
            };
            await this.$store.dispatch('loan/applicationsupdate_loanOfficer', data);
            await this.getData();
            this.$bvModal.hide('modal-officer');
        },
    },
    computed: {
        ...mapGetters({
            applications: 'loan/applications/getApplications',
            totalApplications: 'loan/applications/getTotalApplications',
        }),
    },

    watch: {
        '$route.query.slug'() {
            this.getData();
        },
        '$route.path'() {
            const route = this.routes.find((el) => el.path == this.$route.path);
            if (route) {
                this.getData();
            }
        },
        sortBy() {
            this.getData();
        },
        sortDesc() {
            this.getData();
        },
    },
    created() {
        this.debouncedSearch = debounce(this.getData, 300);
        this.getData();
    },
};
</script>
